/* eslint-disable no-param-reassign */
import { doGraphqlRequest } from 'ducks/requests/operations'
import { MAILBOX_CHANNEL_TYPE } from 'ducks/folders/constants'
import { buildId } from 'util/globalId'
import { selectRequestsByType } from 'ducks/requests/selectors'
import { camelize } from 'util/strings'
import { chunk } from 'util/arrays'
import { selectShouldUseSpeedyGroove } from 'ducks/currentUser/selectors/preferences/selectShouldUseSpeedyGroove'
import debug from 'util/debug'
import { deepCopy } from 'util/objects'
import { PRELOAD_CONVERSATIONS } from '../actionTypes'
import { preloadConversationFullGraphQlResponseSchema } from '../schema'
import { ticketPreloadConversationsQuery } from '../queries'
import { buildConversationRequestKey } from '../utils/request'
import { mergeTicketEntities } from '../utils/entities'

const BULK_UPDATE_KEY = 'tickets-preload'
const PRELOAD_BATCH_SIZE = 5

function groupByConversationId(items) {
  const grouped = []
  const conversationMap = new Map()

  items.forEach(item => {
    const {
      node: { conversationId },
    } = item

    if (!conversationMap.has(conversationId)) {
      // Create a new group for this conversationId
      const group = []
      grouped.push(group)
      conversationMap.set(conversationId, group)
    }

    // Add the item to the correct group
    conversationMap.get(conversationId).push(item)
  })

  return grouped
}

const transformAttachEventGroupIdToEvents = data => {
  if (data?.eventGroups) {
    groupByConversationId(data.eventGroups.edges).forEach(
      eventGroupsByConversationId => {
        eventGroupsByConversationId.forEach((eg, index) => {
          eg.node.isFirstMessage = index === 0
          eg.node.isLastMessage = index === data.eventGroups.edges.length - 1
          eg.node.events.edges.forEach(e => {
            e.node.eventGroupId = eg.node.id
          })
        })
      }
    )
  }
  return data
}

/*
  This method will be used to retrieve the conversations
  Currently there is a massive disparity in the api implementation between tickets and
  rooms. This method will hide those differences and return a standadised interface for
  the folder component.
 */
const doPreloadTicketsBatch = ({ ticketIds, options = {} }) => async (
  dispatch,
  getState
) => {
  const concurrencyKey = `${BULK_UPDATE_KEY}`
  const conversationIds = ticketIds.map(tid => buildId('Conversation', tid))

  return dispatch(
    doGraphqlRequest(
      PRELOAD_CONVERSATIONS,
      ticketPreloadConversationsQuery(),
      {
        conversationIds,
        eventGroupsFilter: {
          conversationIds,
        },
        linkedResourcesFilter: {
          conversationIds,
        },
      },
      {
        ...options,
        app: true,
        concurrency: {
          key: concurrencyKey,
        },
        normalizationSchema: preloadConversationFullGraphQlResponseSchema,
        transformResponse: transformAttachEventGroupIdToEvents,
        transformEntities: mergeTicketEntities(getState),
        meta: {
          channelType: MAILBOX_CHANNEL_TYPE,
          mergeEntities: true,
          attempt: options?.attempt || 1,
        },
      }
    )
  )
}

const getTicketIdsExcludingLoaded = (ticketIds, requestByType) => {
  return ticketIds.filter(tid => {
    const requestKey = camelize(buildConversationRequestKey(tid))
    return requestByType[requestKey]?.loaded !== true
  })
}

export const doPreloadTickets = ({
  ticketIds: inputTicketIds,
  options = {},
}) => async (dispatch, getState) => {
  const state = getState()
  const forced = options.forced || false
  const shouldPreload = selectShouldUseSpeedyGroove(state)
  const allRequestResponses = []
  if (!shouldPreload && !forced) return []

  let requestByType = selectRequestsByType(state)

  let excludingLoaded = getTicketIdsExcludingLoaded(
    inputTicketIds,
    requestByType
  )
  let attempt = 0
  let lastResponses = []
  while (excludingLoaded.length > 0 && attempt < 3) {
    attempt += 1
    const batches = chunk(excludingLoaded, PRELOAD_BATCH_SIZE)

    const requests = Promise.allSettled(
      // eslint-disable-next-line no-loop-func
      batches.map(batch =>
        dispatch(
          doPreloadTicketsBatch({
            ticketIds: batch,
            options: {
              ...options,
              attempt,
            },
          })
        )
      )
    )

    // eslint-disable-next-line no-await-in-loop
    lastResponses = await requests
    lastResponses.forEach(response => {
      allRequestResponses.push(response.value)
    })

    requestByType = selectRequestsByType(getState())
    excludingLoaded = getTicketIdsExcludingLoaded(inputTicketIds, requestByType)
    if (excludingLoaded.length > 0) {
      debug('doPreloadTickets failed to preload tickets and will re-try', {
        excludingLoaded: deepCopy(excludingLoaded),
        lastResponses: deepCopy(lastResponses),
        attempt,
      })
    }
  }
  if ((lastResponses || []).some(r => r.status === 'rejected')) {
    debug(
      'doPreloadTickets failed to preload tickets after 3 attempts and gave up',
      {
        excludingLoaded: deepCopy(excludingLoaded),
        lastResponses: deepCopy(lastResponses),
      }
    )
  }
  return allRequestResponses
}
