/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { doGraphqlRequest } from 'ducks/requests/operations'
import debug from 'util/debug'
import { MAILBOX_CHANNEL_TYPE } from 'ducks/folders/constants'
import { buildId } from 'util/globalId'
import { camelize } from 'util/strings'
import Bugsnag from '@bugsnag/js'
import { FETCH_CONVERSATION } from '../actionTypes'
import { conversationFullGraphQlResponseSchema } from '../schema'
import {
  buildConversationRequestKey,
  isValidTicketId,
  isTicketDeletedError,
  isTicketMergeError,
  isTicketNoAccess,
} from '../utils/request'
import { ticketFullConversationQuery } from '../queries'

import { mergeTicketEntities } from '../utils/entities'

const transformAttachEventGroupIdToEvents = data => {
  if (data?.eventGroups) {
    data.eventGroups.edges.forEach((eg, index) => {
      eg.node.isFirstMessage = index === 0
      eg.node.isLastMessage = index === data.eventGroups.edges.length - 1
      eg.node.events.edges.forEach(e => {
        e.node.eventGroupId = eg.node.id

        const change = e.node.change
        if (
          change &&
          change.__typename === 'IntegrationAdded' &&
          !change.conversationId
        ) {
          change.conversationId = eg.node.conversationId
        }
      })
    })
  }
  return data
}

/*
  This method will be used to retrieve the conversations
  Currently there is a massive disparity in the api implementation between tickets and
  rooms. This method will hide those differences and return a standadised interface for
  the folder component.
 */
export function doFetchTicket({
  conversationId: inputConversationId,
  channelType,
  options = {},
}) {
  return async (dispatch, getState) => {
    if (!isValidTicketId(inputConversationId)) return null
    const conversationId = buildId('Conversation', inputConversationId)
    if (channelType !== MAILBOX_CHANNEL_TYPE) {
      debug(
        `doFetchTicket currently only supports loading the ${MAILBOX_CHANNEL_TYPE} channel type`
      )
    }

    let attempt = 0

    while (attempt < 3) {
      attempt += 1
      try {
        // eslint-disable-next-line no-await-in-loop
        return dispatch(
          doGraphqlRequest(
            FETCH_CONVERSATION,
            ticketFullConversationQuery(),
            {
              conversationId,
              eventGroupsFilter: {
                conversationId,
              },
              linkedResourcesFilter: {
                conversationId,
              },
            },
            {
              ...options,
              app: true,
              concurrency: {
                key: inputConversationId,
              },
              normalizationSchema: conversationFullGraphQlResponseSchema,
              transformResponse: transformAttachEventGroupIdToEvents,
              transformEntities: mergeTicketEntities(getState),
              meta: {
                channelType,
                requestKey: camelize(
                  buildConversationRequestKey(inputConversationId)
                ),
                mergeEntities: true,
                updateSearches: !!options.updateSearches,
              },
              throwOnError: true,
            }
          )
        )
      } catch (error) {
        const noAccessError = isTicketNoAccess(error)
        const deletedError = isTicketDeletedError(error)
        const mergeError = isTicketMergeError(error)

        const isHandled = noAccessError || deletedError || mergeError

        if (isHandled || attempt === 3) {
          if (!isHandled) {
            Bugsnag.notify(
              new Error('doFetchTicket failed to load after 3 attempts', {
                cause: error,
              }),
              // eslint-disable-next-line no-loop-func
              event => {
                // Set the severity level
                event.severity = 'error'

                // Attach additional metadata, including the original error
                event.addMetadata('metaData', {
                  ticketId: inputConversationId,
                  channelType,
                  originalError: {
                    message: error.message,
                    stack: error.stack,
                    name: error.name,
                  },
                })
              }
            )
          }
          throw error
        }

        debug('doFetchTicket failed, retrying', {
          error,
          attempt,
        })
      }
    }
    return null
  }
}
