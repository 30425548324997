import { mergePlain } from 'util/merge'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { buildId, getRawId } from 'util/globalId'
import { withUndoSend } from 'ducks/undoSend/request'
import { CONVERSATION_NEW } from '../actionTypes'
import { updateConversationNewMutation } from '../mutations'
import {
  CONVERSATION_SAVE_UNLOAD_MESSAGE,
  NEW_CONVERSATION_ID,
  SNOOZED,
} from '../constants'
import { conversationCreateEmailGraphQlResponseSchema } from '../schema'
import {
  withAutoRedirect,
  onUpdateAttachEventGroupIdToEvents,
  withBeforeSend,
} from '../utils/request'
import {
  attachmentsToEmailAttachmentInput,
  emailArrayToEmailRecipientInputArray,
  parseToApiSnoozeUntil,
} from '../utils/payload'

export const doCreateEmail = (
  body,
  to,
  cc,
  bcc,
  subject,
  inputAttachments,
  inputCannedReplyId,
  draftId,
  inputState,
  inputSnoozeUntil,
  tagIdsToAdd,
  inputAgentId,
  inputTeamId,
  inputChannelId,
  options = {}
) => async dispatch => {
  const attachments = attachmentsToEmailAttachmentInput(inputAttachments)
  const ticketId = NEW_CONVERSATION_ID
  const agentId = buildId('Agent', inputAgentId)
  const teamId = buildId('Team', inputTeamId)
  const channelId = buildId('Channel', inputChannelId)
  const cannedReplyId = buildId('CannedReply', inputCannedReplyId)

  const state = inputSnoozeUntil ? SNOOZED : inputState
  const snoozeUntil = parseToApiSnoozeUntil(inputSnoozeUntil)
  if (!body || !to || !to[0]) return null

  return dispatch(
    withUndoSend(
      draftId,
      withBeforeSend(
        ticketId,
        draftId,
        withAutoRedirect(
          ticketId,
          (afterStartedActions, afterSuccessActions) => async () =>
            dispatch(
              doGraphqlRequest(
                CONVERSATION_NEW,
                updateConversationNewMutation(),
                {
                  assigned: {
                    agentId,
                    teamId,
                  },
                  channelId,
                  message: {
                    subject,
                    body,
                    to: emailArrayToEmailRecipientInputArray(to, {
                      allowEmptyName: true,
                    }),
                    cc: emailArrayToEmailRecipientInputArray(cc, {
                      allowEmptyName: true,
                    }),
                    bcc: emailArrayToEmailRecipientInputArray(bcc, {
                      allowEmptyName: true,
                    }),
                    attachments,
                  },
                  state,
                  cannedReplyId,
                  draftId,
                  snoozeUntil,
                  tagIds: tagIdsToAdd,
                  skipNotifications: !!options.skipNotifications,
                },
                mergePlain(
                  {
                    app: true,
                    throwOnError: true,
                    concurrency: {
                      key: ticketId,
                      message: CONVERSATION_SAVE_UNLOAD_MESSAGE,
                    },
                    normalizationSchema: conversationCreateEmailGraphQlResponseSchema,
                    transformResponse: onUpdateAttachEventGroupIdToEvents(
                      'conversationCreateEmail'
                    ),
                    moduleOptions: {
                      snackbar: {
                        enabled: true,
                        started: {
                          enabled: true,
                          message: 'Creating your reply...',
                        },
                        success: {
                          enabled: false,
                        },
                        failed: {
                          enabled: true,
                          message: `Oops, we couldn't create your conversation. Please try again in a moment.`,
                        },
                      },
                    },
                    meta: {
                      mergeEntities: true,
                      updateSearches: true,
                    },
                    afterStartedActions,
                    afterSuccessActions,
                  },
                  options
                )
              )
            ),
          mergePlain(
            {
              moduleOptions: {
                autoRedirect: {
                  extractTicketId: response => {
                    return getRawId(
                      response?.conversationCreateEmail?.conversation?.id
                    )
                  },
                  forced: true,
                  operation: 'create',
                },
              },
            },
            options
          )
        )
      )
    )
  )
}
