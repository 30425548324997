import { formatTime, strftime, isThisYear, toDate } from 'util/date'

export const isTicketActionChangeBodyEmpty = body => {
  return (
    !body || body === '<em>Empty</em>' || body === '&lt;em&gt;Empty&lt;/em&gt;'
  )
}

export const getDateString = date => {
  const format = isThisYear(date) ? '%b %d' : '%b %d, %Y'
  return strftime(format, date)
}

export const formatDateForMessage = date => {
  const datetime = toDate(date)
  return [getDateString(datetime), '@', formatTime(datetime)].join(' ')
}

export const fromToUserText = (operation, contact) => {
  if (!contact) return ''
  const { name, email } = contact
  if (name && email) return ` ${operation} ${name} (${email})`
  if (name) return ` ${operation} ${name}`
  if (email) return ` ${operation} ${email}`
  return ''
}
