import { mergePlain } from 'util/merge'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { buildId, getRawId } from 'util/globalId'
import { CONVERSATION_LOG } from '../actionTypes'
import { updateConversationLogMutation } from '../mutations'
import {
  CONVERSATION_SAVE_UNLOAD_MESSAGE,
  NEW_CONVERSATION_ID,
  SNOOZED,
} from '../constants'
import { conversationLogGraphQlResponseSchema } from '../schema'
import {
  withAutoRedirect,
  onUpdateAttachEventGroupIdToEvents,
  withBeforeSend,
} from '../utils/request'
import {
  attachmentsToEmailAttachmentInput,
  emailToEmailRecipientInput,
  parseToApiSnoozeUntil,
} from '../utils/payload'

export const doLog = (
  body,
  to,
  inputSubject,
  inputAttachments,
  inputCannedReplyId,
  draftId,
  inputState,
  inputSnoozeUntil,
  inputAgentId,
  inputTeamId,
  inputChannelId,
  phone,
  options = {}
) => async dispatch => {
  const attachments = attachmentsToEmailAttachmentInput(inputAttachments)
  const ticketId = NEW_CONVERSATION_ID
  const agentId = buildId('Agent', inputAgentId)
  const teamId = buildId('Team', inputTeamId)
  const channelId = buildId('Channel', inputChannelId)
  const cannedReplyId = buildId('CannedReply', inputCannedReplyId)
  const subject = inputSubject || ''

  const state = inputSnoozeUntil ? SNOOZED : inputState
  const snoozeUntil = parseToApiSnoozeUntil(inputSnoozeUntil)
  if (!body) return null

  return dispatch(
    withBeforeSend(
      ticketId,
      draftId,
      withAutoRedirect(
        ticketId,
        (afterStartedActions, afterSuccessActions) => async () =>
          dispatch(
            doGraphqlRequest(
              CONVERSATION_LOG,
              updateConversationLogMutation(),
              {
                assigned: {
                  agentId,
                  teamId,
                },
                channelId,
                message: {
                  subject,
                  body,
                  to:
                    to.length > 0
                      ? emailToEmailRecipientInput(to[0], {
                          allowEmptyName: true,
                        })
                      : null,
                  phone,
                  attachments,
                },
                state,
                cannedReplyId,
                draftId,
                snoozeUntil,
                skipNotifications: !!options.skipNotifications,
              },
              mergePlain(
                {
                  app: true,
                  throwOnError: true,
                  concurrency: {
                    key: ticketId,
                    message: CONVERSATION_SAVE_UNLOAD_MESSAGE,
                  },
                  normalizationSchema: conversationLogGraphQlResponseSchema,
                  transformResponse: onUpdateAttachEventGroupIdToEvents(
                    'conversationLog'
                  ),
                  moduleOptions: {
                    snackbar: {
                      enabled: true,
                      started: {
                        enabled: true,
                        message: 'Logging your conversation...',
                      },
                      success: {
                        enabled: false,
                      },
                      failed: {
                        enabled: true,
                        message: `Oops, we couldn't log your conversation. Please try again in a moment.`,
                      },
                    },
                  },
                  meta: {
                    mergeEntities: true,
                    updateSearches: true,
                  },
                  afterStartedActions,
                  afterSuccessActions,
                },
                options
              )
            )
          ),
        mergePlain(
          {
            moduleOptions: {
              autoRedirect: {
                extractTicketId: response => {
                  return getRawId(response?.conversationLog?.conversation?.id)
                },
                forced: true,
                operation: 'create',
              },
            },
          },
          options
        )
      )
    )
  )
}
