/* eslint-disable no-param-reassign */
import { doGraphqlRequest } from 'ducks/requests/operations'
import { selectWidgetsWithBridged } from 'ducks/widgets/selectors/widgets'
import { isChatChannelType } from 'ducks/channels/channelTypes'
import metrics from 'util/metrics'
import { selectCurrentFolderById } from 'ducks/folders/selectors/folders'
import { isValidNumber } from 'actions/search/isValidNumber'
import { pick } from 'util/objects'
import {
  constructGraphQLFilterObject,
  constructGraphQLOrderByObjectFromOrderBy,
  queryIdToQuery,
} from '../utils/query'
import { SEARCH_CONVERSATIONS } from '../actionTypes'
import { conversationsGraphQlResponseSchema } from '../schema'
import { roomConversationsQuery, ticketConversationsQuery } from '../queries'
import { selectSearchIsLoadedByQueryId } from '../selectors'
import { SEARCH_REQUEST_MODULES } from '../utils/request'
import { selectDefaultSortOrderByQueryId } from '../selectors/selectDefaultSortOrderByQueryId'
import { PAGINATION_MODE } from '../constants'

function hackRoomToConversationResponse(channelType) {
  return data => {
    if (isChatChannelType(channelType) && data?.rooms) {
      data.rooms.nodes.forEach(node => {
        node.internalId = node.id
        node.id = node.number.toString()
        delete node.number
      })
      data.conversations = data.rooms
      delete data.rooms
    }
    return data
  }
}

const TICKET_FILTER_FIELDS = [
  'assigned',
  'channelId',
  'contactId',
  'contactEmail',
  'deleted',
  'draftAuthor',
  'draftType',
  'folderId',
  'keywords',
  'mentionedAgent',
  'rating',
  'starred',
  'state',
  'states',
  'tag',
  'tagName',
  'tags',
  'tagNames',
  'type',
  'beforeCreatedAt',
  'afterCreatedAt',
  'beforeUpdatedAt',
  'afterUpdatedAt',
  'customFields',
]
/*
  This method will be used to retrieve the conversations
  Currently there is a massive disparity in the api implementation between tickets and
  rooms. This method will hide those differences and return a standadised interface for
  the folder component.
 */
export const doFetchConversations = ({
  queryId,
  cursor,
  channelType,
  size = 20,
  options = {},
}) => async (dispatch, getState) => {
  const state = getState()
  const rawFilter = constructGraphQLFilterObject(queryId, state) || {}
  const isChatChannel = isChatChannelType(channelType)
  const filter = isChatChannel
    ? rawFilter
    : pick(TICKET_FILTER_FIELDS, rawFilter)
  const query = queryIdToQuery(queryId)
  const defaultOrderBy = isChatChannel
    ? null
    : selectDefaultSortOrderByQueryId(state, queryId)

  let orderBy = constructGraphQLOrderByObjectFromOrderBy(
    query?.orderBy || defaultOrderBy
  )

  const searchLoaded = selectSearchIsLoadedByQueryId(state, queryId)
  const isTicketIdSearch = filter.keywords && isValidNumber(filter.keywords)

  // In classic view you have the problem that when
  // 1. You've searched for a ticket by its id
  // 2. The system automatically opened the conversation for you
  // 3. Now you want to go back to the search results page.
  // The system will then automatically redirect back to the ticket page leaving you stuck
  // In order to address this we'll only allow the auto redirect to the ticket page
  // the first time the search is loaded.
  if (searchLoaded && isTicketIdSearch) return null

  // HACK: These should come from the backend API as the default sort order
  // for the filters. Incorporate together with filter permissions for chat.
  if (!orderBy && filter?.folderId && filter?.channelId) {
    const [, widgetId] = filter.channelId.split('_')
    const widget = selectWidgetsWithBridged(state).find(w => w.id === widgetId)
    if (widget) {
      const folder = selectCurrentFolderById(state, filter.folderId)
      if (['New messages', 'Ending soon'].includes(folder?.name)) {
        orderBy = { field: 'EXPIRES_AT', direction: 'ASC' }
      }
    }
  }

  metrics.startOrEnhanceTimer(`search-load-${queryId}`, {
    metric: 'search-load',
    logname: 'main',
    hot: false,
    queryId,
  })

  return dispatch(
    doGraphqlRequest(
      SEARCH_CONVERSATIONS,
      isChatChannel ? roomConversationsQuery() : ticketConversationsQuery(),
      {
        filter,
        queryId,
        orderBy,
        cursor: cursor !== 'null' ? cursor : undefined,
        size,
      },
      {
        ...options,
        modules: SEARCH_REQUEST_MODULES,
        app: true,
        transformResponse: hackRoomToConversationResponse(channelType),
        normalizationSchema: isChatChannel
          ? null
          : conversationsGraphQlResponseSchema,
        meta: {
          channelType,
          mergeEntities: true,
          paginationMode: PAGINATION_MODE.ES_CURSOR,
        },
      }
    )
  )
}
