import { selectChannelCustomFields } from 'ducks/crm/channels/selectors/selectChannelCustomFields'
import { createSelector } from 'reselect'
import { DATE_SEARCH_QUERY_TYPE, suggestionList } from 'util/search/constants'
import { DATE, DROPDOWN, MULTI_SELECT } from 'ducks/crm/customFields/types'
import { selectIsCustomFieldsEnabled } from 'selectors/app/selectAccountPreferences'
import { customFieldKeyToSearchKey } from '../utils/query'

const hintForCustomField = cf => {
  if ([DROPDOWN, MULTI_SELECT].includes(cf.type)) {
    return cf.options.map(o => o.label).join(' ')
  } else if ([DATE].includes(cf.type)) {
    return 'yyyy-mm-dd'
  }
  return cf.name
}

const typeForCustomField = cf => {
  if (cf.type === DATE) {
    return DATE_SEARCH_QUERY_TYPE
  }
  return undefined
}

export const selectSuggestionList = createSelector(
  selectChannelCustomFields,
  selectIsCustomFieldsEnabled,
  (customFields, isCustomFieldsEnabled) => {
    if (!isCustomFieldsEnabled) return suggestionList

    const suggestionListWithCustomFields = [...suggestionList]
    customFields.forEach(cf => {
      if (!cf.key) return
      const searchKey = customFieldKeyToSearchKey(cf.key)

      // Kevin R: The removal of the mailbox id from the custom field key can result in duplicate
      // suggestions being returned here. This however wont be an issue as Tony has requested that
      // all keys be globall unique. This means that while from a system perspective we could have
      // cfm_1_product_type and cfm_2_product_type which both normalize to #ProductType, it wont
      // however happen due to backend and frontend validation. If in the future we decide to allow
      // mailbox specific custom field keys, we can remove that validation and change the
      // customFieldKeyToSearchKey function to return unique keys. For now if this is detected we just
      // move along
      const suggestion = `${searchKey}:`
      if (suggestionListWithCustomFields.some(s => s.suggestion === suggestion))
        return

      suggestionListWithCustomFields.push({
        suggestion,
        hint: hintForCustomField(cf),
        searchQuery: suggestion,
        type: typeForCustomField(cf),
      })
    })
    return suggestionListWithCustomFields
  }
)
